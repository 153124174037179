import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Chip, Typography, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const intersectionBy = require('lodash/intersectionBy')

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  selectField: {
    // flexBasis: '80%',
    // width: '100%'
    flex: 1
  },
  selectOption: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const SelectInputField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, options, disabledOptions, fixedOptions = [], groupBy, optionLabel, optionValueKey, fullObjects, multiple, filterSelected, freeType, displayComponent }) => {
  const [loading, setLoading] = useState(false)
  const [selectOptions, setSelectOptions] = useState(options && !(typeof options === 'function' || options instanceof Promise) ? options : [])
  const styles = useStyles()

  const handleChange = useCallback((event, newValue) => {
    // const selected = typeof newValue === 'object' ? newValue[optionValueKey] : newValue
    // console.log('selected', newValue)
    onChange(event, newValue, !fullObjects && optionValueKey)
  }, [onChange, optionValueKey, fullObjects]);

  useEffect(() => {
    // console.log('OPTIONS', options, options && (typeof options === 'function'))
    // if (options && (typeof options === 'function')) {
    //   setLoading(true)
    //   const opts = options()
    //   console.log('OPTS', opts)
    //   Promise.resolve(typeof opts === 'function' ? opts() : opts).then(setSelectOptions).then(() => setLoading(false))
    // } else {
    //   setSelectOptions(options)
    // }
    if (options && (typeof options === 'function' || options instanceof Promise)) {
      setLoading(true)
      Promise.resolve(typeof options === 'function' ? options() : options).then(setSelectOptions).then(() => setLoading(false))
    } else {
      setSelectOptions(options)
    }
  }, [options])

  const getSelectedValue = useCallback((option, val) => {
    const optLabel = typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel]
    // if (typeof option === 'object') console.log('SELECT VALUE', (fullObjects || typeof val === 'object'), option, optionLabel, optLabel, val)
    return (!!option && typeof option === 'object') ? (fullObjects || typeof val === 'object') ? JSON.stringify(option[optionValueKey]) === JSON.stringify(val[optionValueKey]) : JSON.stringify(optLabel) === JSON.stringify(val) : JSON.stringify(option) === JSON.stringify(val)
  }, [optionLabel, optionValueKey, fullObjects])

  // const fieldValue = ((selectOptions && selectOptions.length) && optionValueKey && optionLabel) ? selectOptions.find(o => o[optionValueKey] === value) && (typeof optionLabel === 'function' ? optionLabel(selectOptions.find(o => o[optionValueKey] === value)) : selectOptions.find(o => o[optionValueKey] === value)[optionLabel]) || (multiple ? [] : null) : loading ? (multiple ? [] : null) : (value || (multiple ? [] : null))

  const fieldValue = useMemo(() => {
    // console.log('FIELD VALUE', value, optionValueKey, optionLabel, multiple, selectOptions, loading)
    if ((selectOptions && selectOptions.length) && optionValueKey && optionLabel) {
      if (multiple) {
        return fullObjects ? intersectionBy(value, selectOptions, optionValueKey) : selectOptions.filter(o => (value || []).includes(o[optionValueKey]))//.map(o => typeof optionLabel === 'function' ? optionLabel(o) : o[optionLabel])
      } else {
        return selectOptions.find(o => JSON.stringify(o[optionValueKey]) === JSON.stringify(value)) && (typeof optionLabel === 'function' ? optionLabel(selectOptions.find(o => JSON.stringify(o[optionValueKey]) === JSON.stringify(value))) : selectOptions.find(o => JSON.stringify(o[optionValueKey]) === JSON.stringify(value))[optionLabel]) || null
      }
    } else {
      return loading ? (multiple ? [] : null) : (value || (multiple ? [] : null))
    }

  }, [value, fullObjects, optionValueKey, optionLabel, multiple, selectOptions, loading])

  const selectInputRender = useCallback((params) => (
    <TextField
      {...params}
      variant="outlined"
      error={invalid}
      placeholder={fieldValue && !!fieldValue.length ? '' : loading ? 'Loading...' : placeholder}
      fullWidth
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  ), [fieldValue, loading, placeholder, invalid])

  const selectOptionRender = useCallback((option) => {
    const optLabel = (!!option && typeof option === 'object') ? typeof optionLabel === 'function' ? optionLabel(option) : optionLabel && option[optionLabel] : option
    const optValueKey = (!!option && typeof option === 'object') ? option[optionValueKey] : option
    const OptionComponent = displayComponent ? (typeof displayComponent === 'function' ? displayComponent(option) : displayComponent[optValueKey]) : null
    return (
      <div className={styles.selectOption}>
        {optLabel}
        {!!OptionComponent && <OptionComponent />}
      </div>
    )
  }, [optionLabel, optionValueKey, displayComponent, styles])

  const chipRender = useCallback((tagValue, getTagProps) => (
    tagValue.map((option, index) => {
      const optLabel = typeof option === 'object' ? typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel] : option
      const optValueKey = typeof option === 'object' ? option[optionValueKey] : option
      return <Chip
        label={optLabel}
        {...getTagProps({ index })}
        disabled={disabled || fixedOptions.indexOf(optValueKey) !== -1}
      />
    })
  ), [optionLabel, optionValueKey, fixedOptions, disabled])

  return (
    <div className={styles.root}>
      {!!label && <Typography className={styles.label}>{label}</Typography>}
      <Autocomplete
        size='small'
        className={styles.selectField}
        multiple={multiple}
        id="tags-outlined"
        options={selectOptions}
        getOptionDisabled={disabledOptions}
        getOptionLabel={option => `${typeof option === 'object' ? typeof optionLabel === 'function' ? optionLabel(option) : option[optionLabel] : option}`}
        getOptionSelected={getSelectedValue}
        groupBy={groupBy && ((option) => option[groupBy])}
        value={fieldValue}
        // error={invalid}
        disabled={disabled || loading}
        loading={loading}
        onChange={handleChange}
        filterSelectedOptions={filterSelected}
        freeSolo={freeType}
        renderInput={selectInputRender}
        renderOption={selectOptionRender}
        renderTags={chipRender}
      />
    </div>
  );
})

// SelectInputField.defaultProps = {
//   placeholder: 'Please select...',
//   multiple: false,
//   filterSelected: true,
//   freeType: false,
//   options: []
// }

export default SelectInputField;
