import React, { useCallback, useRef, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import StandardField from './StandardField';
import { useMemoCompare } from '../../../hooks/hooks'
import { useDrop } from 'react-dnd'

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  dropzone: {
    width: '95%',
    height: '6px',
    margin: '2px auto 2px auto',
    opacity: '0.3'
  },
  addbutton: {
    marginTop: '8px'
  }
});

const QuestionDropTarget = React.memo(({ onDrop }) => {
  const styles = useStyles();
  const [{ isOver }, drop] = useDrop({
    accept: 'question',
    drop: (item) => onDrop(item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })
  return <div style={{ backgroundColor: isOver ? 'gold' : 'transparent' }} className={styles.dropzone} ref={drop} />
})

const MultiQuestionField = React.memo(({ value, label, keyLabel, fieldKey, disabled, required, conditional, conditionMet, onChange, invalid, invalidate, showGoals, showTerminate, ...restProps }) => {
  const styles = useStyles();
  const valueRef = useRef();
  const keyRef = useRef();

  valueRef.current = value || []

  // console.log('QUESTIon builder', showTerminate)

  const questionKeys = useMemoCompare(value ? value.map(x => (x.matrix ? (x.matrixKeys || []).map(option => ({
    key: x.questionKey,
    option: option.option,
    display: `${x.questionKey} \u2011 ${option}`
  })) : {
    key: x.questionKey,
    display: x.questionKey
  })).flat().filter(x => !!x) : [], isEqual);

  useEffect(() => {
    if (!isEqual(keyRef.current, questionKeys)) {
      const newQuestions = cloneDeep(valueRef.current).map((q) => {
        if (!q.crosstabKeys || q.crosstabKeys.filter(crosstabKey => !questionKeys.find(x => x.key === crosstabKey.key && (crosstabKey.option ? x.option === crosstabKey.option : true))).length) q.crosstabKeys = []
        return q
      })
      onChange(null, newQuestions)
    }
    keyRef.current = questionKeys
  }, [questionKeys, onChange])

  const handleAddQuestion = useCallback(() => {
    const newQuestion = {
      question: '',
      multiple: false,
      excluded: false,
      hasCrosstab: false,
      crosstabKeys: [],
      freeNumeric: false,
      answers: [{}],
    }
    const newValue = [...valueRef.current, newQuestion]
    onChange(null, newValue)
  }, [onChange])

  const handleExpandAll = useCallback(() => {
    let upd = valueRef.current.map(i => ({...i, collapsed: false}));
    onChange(null, upd)
  }, [onChange])

  const handleCollapseAll = useCallback(() => {
    let upd = valueRef.current.map(i => ({...i, collapsed: true}));
    onChange(null, upd)
  }, [onChange])

  const handleQuestionChange = useCallback((event, newValue, questionIndex) => {
    const newQuestions = cloneDeep(valueRef.current)
    newQuestions[questionIndex] = newValue
    // console.log('NEW QUESTION VALUE IS', JSON.stringify(newValue), questionIndex)
    onChange(null, newQuestions)
  }, [onChange])

  const handleRemoveQuestion = useCallback((index) => {
    const newQuestions = cloneDeep(valueRef.current)
    newQuestions.splice(index, 1)
    invalidate && invalidate(`${fieldKey}-question${index}`, false)
    onChange(null, newQuestions)
  }, [onChange, invalidate, fieldKey])

  const handleReorderQuestion = useCallback((item, index) => {
    const newQuestions = cloneDeep(valueRef.current)
    const x = newQuestions[item.index]
    newQuestions.splice(item.index, 1)
    newQuestions.splice(index, 0, x)
    onChange(null, newQuestions)
  }, [onChange])

  return (
    <div className={styles.root}>
      {label && <Typography className={styles.label}>{label}</Typography>}
      {value && value.length > 0 && <><Button variant="outlined" onClick={handleExpandAll}>
         Expand All
        </Button>{'\u0020'}
        <Button variant="outlined" onClick={handleCollapseAll}>
         Collapse All
        </Button></>}
      {value && value.map((question, i) =>
        <React.Fragment key={`questionfragment${i}`}>
          {(!disabled && value.length > 1) && <QuestionDropTarget key={`questiondrop${i}`} onDrop={(item) => handleReorderQuestion(item, i)} />}
          {(disabled || value.length === 1) && <div className={styles.dropzone} />}
          <StandardField
            type='question'
            fieldKey={`${fieldKey}-question${i}`}
            key={`${fieldKey}-question${i}`}
            questionKey={`${fieldKey}-question${i}`}
            questionKeys={questionKeys}
            index={i}
            value={question}
            required={true}
            // required={required || (conditional && conditionMet)}
            disabled={disabled}
            showTerminate={showTerminate}
            showGoals={showGoals}
            onRemove={handleRemoveQuestion}
            onChange={handleQuestionChange}
            draggable={!disabled}
            invalidate={invalidate}
            label={keyLabel}
            {...restProps}
          />
          {(!disabled && (value.length > 1 && i === value.length - 1)) && <QuestionDropTarget key={`questiondrop${value.length}`} onDrop={(item) => handleReorderQuestion(item, value.length)} />}
          {(disabled || value.length === 1) && <div className={styles.dropzone} />}
        </React.Fragment>
      )}
      <div className={styles.addbutton}>
        <Button variant="outlined" disabled={disabled} onClick={handleAddQuestion}>
          <AddIcon /> Add Segment Question
        </Button>
      </div>
    </div>
  );
})

MultiQuestionField.defaultProps = {

}

export default MultiQuestionField;
