import React, { useState, useCallback } from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withTheme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 0,
        zIndex: 1
    },
    rootShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: ({rightMargin}) => rightMargin,
    },
    toolbar: {
        width: 'auto',
        height: 'calc(5rem + 5px)',
        backgroundColor: theme.palette.toolbar || theme.palette.primary.main
    },
    title: {
        // display: 'flex',
        // alignItems: 'center',
        // flexGrow: '1',
        padding: '0 1em 0 1em'
    },
    image: {
        height: 'auto',
        width: 'auto',
        marginLeft: 'auto',
        marginRight: '25px',
        maxWidth: '200px'
    }
}));

const StandardNavBar = React.memo(({ title, image, navDrawerComponent: NavDrawerComponent, drawerProps, auxActions = [], rightMargin = 0 }) => {
    console.log('render nav bar')
    const [drawerOpen, setDrawerOpen] = useState(false);
    const styles = useStyles({ rightMargin })

    const handleDrawerOpen = useCallback((e) => {
        e.currentTarget.blur()
        setDrawerOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const closeOpenDrawer = useCallback((event) => {
        if (drawerOpen) {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setDrawerOpen(false)
        }
    }, [drawerOpen])

    return (
        <div className={clsx(styles.root, { [styles.rootShift]: !!rightMargin })}>
            <AppBar position="static">
                <Toolbar className={styles.toolbar}>
                    {NavDrawerComponent && <IconButton color="inherit" onClick={handleDrawerOpen}>
                        <MenuIcon />
                    </IconButton>}
                    {(title && image) && <Typography variant="h5" noWrap className={styles.title}>
                        {title}
                    </Typography>}
                    {image && <img src={image} alt={title} className={styles.image} />}
                    {(title && !image) && <Typography variant="h5" noWrap className={styles.title}>
                        {title}
                    </Typography>}
                    {auxActions.filter(x => !x.hidden).map(({ iconComponent: IconComponent, onClick }) => (
                        <IconButton color="inherit" onClick={onClick}>
                            <IconComponent />
                        </IconButton>
                    ))}
                </Toolbar>
            </AppBar>
            {NavDrawerComponent && <div className={styles.root}
                onClick={closeOpenDrawer}
                onKeyDown={closeOpenDrawer}
            >
                <NavDrawerComponent open={drawerOpen} onClose={handleDrawerClose} onOpen={handleDrawerOpen} {...drawerProps} />
            </div>}
        </div>
    );

})

export default withTheme(StandardNavBar);
