import React from 'react';
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const Root = () => {

    return (
        <RecoilRoot>
            <LocalizationProvider dateAdapter={MomentUtils}>
                <DndProvider backend={HTML5Backend}>
                    <Router>
                        <AppRoutes />
                    </Router>
                </DndProvider>
            </LocalizationProvider>
        </RecoilRoot>
    )
}

export default Root;