
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
    buttonRoot: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        fontSize: '60%',
        borderRadius: 6,
        width: '100%',
        // border: 0,
        // color: 'white',
        // height: 48,
        padding: 0,
        lineHeight: 1.25,
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0.5rem 0 0.5rem'
    }
})

export const StatsLabelButton = React.memo(({ dataKey, altLabel, onSelect, selected, activeColor, unselectedColor, textColor, disabled }) => {

    const styles = useStyles();

    return (
        <Button variant="contained" disabled={disabled} onClick={onSelect} style={{ color: selected ? textColor : 'white', backgroundColor: selected ? activeColor : unselectedColor }} classes={{ root: styles.buttonRoot, label: styles.buttonLabel }}>
            {altLabel || dataKey.map((k, i) =>
                <span key={`datakey-${k}-${i}`}>{k}</span>
            )}
        </Button>
    )
})