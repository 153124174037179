import React, { useRef, useCallback } from 'react';
import { Collapse, IconButton, Checkbox, Typography, TextField, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import SelectInputField from './SelectInputField'
import CheckboxField from './CheckboxField';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    padding: '6px',
    margin: '0 8px 0 8px',
    border: '2px solid #eee',
    borderRadius: '8px',
    transform: 'translate3d(0, 0, 0)'
  },
  box: {
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
  },
  addbutton: {
    display: 'flex',
    padding: '6px',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  answers: {
    padding: '4px',
    width: '100%',
    borderLeft: '2px solid #eee'
  },
  checkbox: {
    flexBasis: '50%',
    // textAlign: 'right',
    // marginRight: '10px'
  },
  checkboxPadding: {
    padding: '4px',
  },
  checkboxContent: {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content'
  },
  checkLabel: {
    // width: '100%'
  },
  label: {
    textAlign: 'right',
    marginRight: '10px !important'
  },
  fieldLabel: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px !important',
  },
  answerrow: {
    display: 'flex'
  },
  textField: {
    flex: 1,
    padding: '4px'
  },
  keyInput: {
    // width: '3.5em',
    width: '20%',
    padding: '4px'
  },
  shapeInput: {
    // width: '3.5em',
    // width: '2rem',
    // padding: '4px'
  },
  timeField: {
    // width: '3.5em',
    width: '20%',
    padding: '4px'
  },
  colon: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px'
  },
  plus: {
    margin: '2px'
  },
  templates: {
    display: 'flex',
    flexBasis: '50%',
  },
});

const userAccessTypes = {
  group: 'Group/Role-Based',
  private: 'Individual Assignment',
}

const userRoleOptions = [
  'Client'
]

const staffRoleOptions = [
  {
    name: 'Session Level',
    id: 'session'
  },
  {
    name: 'Audience Level',
    id: 'audience'
  },
  {
    name: 'Tech Check Level',
    id: 'tc'
  },
  {
    name: 'Balance Level',
    id: 'balance'
  },
  {
    name: 'Client Interface Level',
    id: 'client'
  },
  {
    name: 'Live Dial Level',
    id: 'dial'
  },
  {
    name: 'Video Mangement Level',
    id: 'video'
  }
]

const ChatChannelField = React.memo(({ value, disabled, placeholder, onChange, onRemove }) => {
  const styles = useStyles(value)

  const valueRef = useRef();
  valueRef.current = value

  const handleNameChange = useCallback((event) => {
    const newChannel = { ...valueRef.current }
    newChannel.name = event.target.value
    onChange(event, newChannel)
  }, [onChange]);

  const handleInternalChange = useCallback((event) => {
    event.currentTarget.blur()
    const newChannel = { ...valueRef.current }
    newChannel.internal = event.target.checked
    newChannel.type = 'group'
    newChannel.userAccess = []
    onChange(event, newChannel)
  }, [onChange]);

  const handleUserAccessTypeChange = useCallback((event) => {
    event.currentTarget.blur()
    const newChannel = { ...valueRef.current }
    newChannel.type = event.target.value
    onChange(event, newChannel)
  }, [onChange]);

  const handleUserRoleChange = useCallback((event, newValue) => {
    event.currentTarget.blur()
    const newChannel = { ...valueRef.current }
    newChannel.userAccess = newValue
    onChange(event, newChannel)
  }, [onChange]);

  const handleStaffAccessChange = useCallback((event, newValue) => {
    event.currentTarget.blur()
    const newChannel = { ...valueRef.current }
    newChannel.staffAccess = newValue.map(x => x.id)
    onChange(event, newChannel)
  }, [onChange]);

  const handleRestrictedChange = useCallback((event, newValue) => {
    const newChannel = { ...valueRef.current }
    newChannel.restricted = newValue
    onChange(event, newChannel)
  }, [onChange]);

  return (
    <div className={styles.root}>
      <div className={styles.box}>
        <TextField className={styles.textField} variant="outlined" size="small" placeholder={placeholder || 'Enter Channel Name'} error={!value.name} disabled={disabled} value={value.name || ''} onChange={handleNameChange} />
        <div className={styles.checkboxPadding}>
          <div className={styles.checkboxContent}>
            <Checkbox size="small" color="primary" checked={value.internal} disabled={disabled || value.default} onChange={handleInternalChange} />
            <Typography className={styles.checkLabel}>Staff-Only Channel</Typography>
          </div>
        </div>
        <IconButton disabled={disabled || value.default} onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </div>
      <div className={styles.box}>
        <SelectInputField label="Staff Access Levels" options={staffRoleOptions} optionLabel='name' optionValueKey='id' placeholder={placeholder || 'Select Allowed Staff Access Levels'} disabled={disabled || value.default} fixedOptions={['session']} multiple={true} filterSelected={true} value={value.staffAccess} onChange={handleStaffAccessChange} />
      </div>
      <Collapse in={!value.internal}>
        <div className={styles.box}>
          <Typography className={styles.fieldLabel}>Allow Access To:</Typography>
          <TextField
            className={styles.textField}
            value={value.type || ''}
            onChange={handleUserAccessTypeChange}
            variant="outlined"
            size="small"
            placeholder={placeholder || 'Select User Access Type'}
            disabled={disabled || value.default}
            error={!value.type}
            select
          >
            {Object.entries(userAccessTypes).map(([type, label]) => (
              <MenuItem key={`accesstype${type}`} value={type}>{label}</MenuItem>
            ))}
          </TextField>
        </div>
        <div className={styles.box}>
          <SelectInputField label="User Roles" options={userRoleOptions} placeholder={placeholder || 'Select Allowed User Roles'} disabled={disabled || value.default} multiple={true} filterSelected={true} value={value.userAccess} onChange={handleUserRoleChange} />
        </div>
        <Collapse in={value.type === 'private'}>
          <div className={styles.box}>
            {/* <div className={styles.checkboxContent}>
              <Checkbox size="small" color="primary" checked={value.restricted} disabled={disabled || value.default} onChange={handleRestrictedChange} />
              <Typography className={styles.checkLabel}>Restrict Message Viewing</Typography>
            </div> */}
            <CheckboxField label="Restrict Message Viewing to Users" value={value.restricted} disabled={disabled || value.default} onChange={handleRestrictedChange} />
          </div>
        </Collapse>
      </Collapse>
    </div>
  );
})

// ChatChannelField.defaultProps = {
//   label: ''
// }

export default ChatChannelField;
