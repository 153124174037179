import React, { useCallback, useEffect, useRef } from 'react';
import { Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import StandardField from './StandardField';
import { useMemoCompare } from '../../../hooks/hooks'
import NumberInputField from './NumberInputField';
import SelectInputField from './SelectInputField';
import { findIndex } from 'lodash';

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
  },
  // label: {
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   flexBasis: '20%',
  //   // textAlign: 'right',
  //   marginRight: '10px !important',
  //   padding: '5px',
  // },
  rewardsLabel: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px !important',
    padding: '5px',
  },
  splitKeys: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '30%',
    textAlign: 'right',
    marginRight: '10px !important',
    padding: '5px',
    // flexBasis: '80%',
    // padding: '4px',
    // borderLeft: '2px solid #eee'
  },
  rewards: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '80%',
    padding: '4px',
    borderLeft: '2px solid #eee'
  },
  rewardsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
});

const valueIndex = (value, group, splitKey) => (value.rewards || []).findIndex(x => x.key === group.key && x.value === group.value && x.splitValue === splitKey)

const RewardsField = React.memo(({ value = {}, options, optionLabel, optionValueKey, selectedValueKey, selectedValueMap, disabledOptions, groups, split, splitKeys, currencySymbol, maxValue, label, placeholder, fieldKey, required, conditional, conditionMet, disabled, onChange, invalid, invalidate, ...restProps }) => {
  const styles = useStyles();
  const valueRef = useRef();
  const onChangeRef = useRef();
  const splitRef = useRef();

  valueRef.current = value || {}
  onChangeRef.current = onChange
  splitRef.current = split

  // const handleRewardChange = useCallback((newValue, valueKey, key) => {
  //   const newRewards = cloneDeep(valueRef.current)
  //   newRewards[valueKey] = key ? { ...(newRewards[valueKey] || {}) } : newValue
  //   if (key) newRewards[valueKey][key] = newValue
  //   console.log('reward change!', newValue, key, newRewards)
  //   onChange(null, newRewards)
  // }, [onChange])

  useEffect(() => {
    if (split !== splitRef.current) {
      console.log('split keys changed -- CLEARING REWARDS!!')
      const newRewards = cloneDeep(valueRef.current)
      newRewards.rewards = []
      onChangeRef.current(null, newRewards)
      splitRef.current = split
    }
  }, [split])

  const handlePaymentTypeChange = useCallback((event, newValue) => {
    const newRewards = cloneDeep(valueRef.current)
    newRewards.paymentType = {}
    selectedValueMap.forEach((key, prop) => newRewards.paymentType[prop] = newValue?.[key])
    console.log('payment type change!', newValue, newRewards)
    onChangeRef.current(null, newRewards)
  }, [onChange, selectedValueMap])

  const handleRewardChange = useCallback((newValue, index, group, splitValue) => {
    const newRewards = cloneDeep(valueRef.current)
    newRewards.rewards = [...(newRewards.rewards || [])]
    if (index === -1) {
      newRewards.rewards.push({
        amount: newValue,
        key: group.key,
        value: group.value,
        splitValue
      })
    } else {
      newRewards.rewards[index] = {
        amount: newValue,
        key: group.key,
        value: group.value,
        splitValue
      }
    }
    console.log('reward change!', newValue, group, splitValue, newRewards)
    onChangeRef.current(null, newRewards)
  }, [onChange])

  return (
    <div className={styles.root}>
      <Typography className={styles.rewardsLabel}>{label}</Typography>
      <div className={styles.rewards}>
        <SelectInputField
          placeholder={placeholder}
          options={options}
          optionLabel={optionLabel}
          optionValueKey={optionValueKey}
          disabledOptions={disabledOptions}
          value={value?.paymentType?.[selectedValueKey]}
          displayComponent={(option) => () => <span>{option.currencyCode}</span>}
          required={true}
          // required={required || (conditional && conditionMet)}
          onChange={handlePaymentTypeChange}
          invalidate={invalidate}
          disabled={disabled}
        />
        {split ? splitKeys.map((splitKey, gi) => (<div className={styles.rewardsRow} key={`reward${splitKey}${gi}`}>
          <div className={styles.splitKeys}>
            {!gi && <Typography>&nbsp;</Typography>}
            <Typography>{splitKey || 'N/A'}</Typography>
          </div>
          {groups.map((group) => {
            const i = valueIndex(value, group, splitKey)
            return <NumberInputField key={`reward${group.key}${group.value}${splitKey || 'N/A'}`} label={!gi && group.label} labelPosition="top" value={value.rewards?.[i]?.amount} prefix={(group.prefix || '') + currencySymbol} maxValue={maxValue || 100} decimals={2} required={true} disabled={disabled} onChange={(e, v) => handleRewardChange(v, i, group, splitKey)} />
          })}
        </div>)) : <div className={styles.rewardsRow}>
          {groups.map((group) => {
            const i = valueIndex(value, group)
            return <NumberInputField key={`reward${group.key}${group.value}`} label={group.label} labelPosition="top" value={value.rewards?.[i]?.amount} prefix={(group.prefix || '') + currencySymbol} maxValue={maxValue || 100} decimals={2} required={true} disabled={disabled} onChange={(e, v) => handleRewardChange(v, i, group)} />
          })}
        </div>}
      </div>
    </div>
  );
})

// MultiChatChannelField.defaultProps = {

// }

export default RewardsField;
