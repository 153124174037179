import React, { useCallback } from 'react';
import { Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input'

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  subroot: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  sublabel: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: 'calc(20% + 16px)'
  },
  chipField: {
    flexBasis: '80%'
    // width: '100%'
  },
  chipRoot: {
    padding: '6px 0 0 6px !important'
  },
  chipChip: {
    float: 'left',
    margin: '0 6px 6px 0'
  },
  chipInput: {
    padding: '0 !important',
    margin: '0 6px 6px 0 !important'
  }
});

const ChipInputField = React.memo(({ value, label, disabled, placeholder, regexp, onChange, invalid, large, sublabel }) => {
  const styles = useStyles()

  const handleValidate = useCallback((data) => {
    return regexp ? regexp.test(data) : true 
  }, [regexp])

  const handleAdd = useCallback((data) => {
    const newValue = [...(value || []), data]
    onChange(null, newValue)
  }, [value, onChange])

  const handleRemove = useCallback((data, index) => {
    const newValue = [...(value || [])]
    newValue.splice(index, 1)
    onChange(null, newValue)
  }, [value, onChange]);

  return (
    <>
      <div className={styles.root}>
        {label && <Typography className={styles.label}>{label}</Typography>}
        <ChipInput className={styles.chipField}
          variant="outlined"
          size="small"
          classes={{
            inputRoot: styles.chipRoot,
            chip: styles.chipChip,
            input: styles.chipInput
          }}
          margin="dense"
          chipRenderer={chipRenderer}
          newChipKeyCodes={[13, 188]}
          newChipKeys={['Enter', 'Comma']}
          error={invalid}
          disabled={disabled}
          value={value || []}
          placeholder={placeholder || 'Enter text'}
          alwaysShowPlaceholder={false}
          onBeforeAdd={handleValidate}
          onAdd={handleAdd}
          onDelete={handleRemove}
          multiline={large}
          rows={large ? 3 : 1} 
          />
      </div>
      {sublabel && <div className={styles.subroot}>
        <Typography variant="caption" className={styles.sublabel}>{typeof sublabel === 'function' ? sublabel() : sublabel}</Typography>
      </div>}
    </>
  );
})

const chipRenderer = ({ value, text, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => (
  <Chip
    key={key}
    className={className}
    size="small"
    style={{
      pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
      // backgroundColor: isFocused ? blue[300] : undefined
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text}
  />
)

export default ChipInputField;
