import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ClearIcon from '@material-ui/icons/Clear';
import Files from 'react-butterfiles'

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    // width: '100%'
  },
  photoBox: {
    border: ({ url }) => url ? 'none' : 'solid 1px black',
    minWidth: '120px',
    minHeight: '70px',
    maxWidth: '300px',
    maxHeight: '175px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    transform: 'none'
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '8px'
  },
  button: {
    margin: '4px 0px 4px 0px',
    width: '100%'
  }
});

let intg = /[0-9]+/

// const timeStringFromMS = (time) => {
//   const hours = time / (3600000)
//   const remainder = time % (3600000)
//   return `${Math.floor(hours)}`.padStart(2, '0') + ":" + `${Math.floor((remainder) / (6000))}`.padStart(2, '0') + ":" + `${Math.ceil((remainder) % (6000) / (1000))}`.padStart(2, '0')
// }

const PhotoUploadField = React.memo(({ value, resolver, label, disabled, onChange, invalid, onButtonClick }) => {
  const [url, setUrl] = useState()
  const [loading, setLoading] = useState(false)
  // const inputRef = useRef({})
  const styles = useStyles({ url })

  useEffect(() => {
    if (value && typeof value === 'string' && resolver && typeof resolver === 'function') {
      setLoading(true)
      Promise.resolve(resolver(value)).then(setUrl).then(() => setLoading(false))
    } else {
      if (value instanceof File) {
        const reader = new FileReader();
        reader.onload = () => setUrl(reader.result);
        reader.readAsDataURL(value);
      } else {
        setUrl(value)
      }      
    }
  }, [value, resolver])

  const onPhotoUpload = useCallback(([file]) => {
    onChange(null, file.src.file)
  }, [])

  const handleClearPhoto = useCallback(() => {
    onChange(null, null)
  }, [onChange])


  return (
    <div className={styles.root}>
      {label && <Typography className={styles.label}>{label}</Typography>}
      <div className={styles.photoBox}>
        {loading && <CircularProgress />}
        {url && <img className={styles.image} src={url} />}
      </div>
      <div className={styles.buttonBox}>
        <Files
          multiple={false}
          maxSize="2mb"
          multipleMaxSize="10mb"
          accept={['image/png']}
          onSuccess={onPhotoUpload}
        // onError={onError}
        >
          {({ browseFiles }) => (
            <Button variant="outlined" size="small" className={styles.button} startIcon={<AddPhotoAlternateIcon />} onClick={browseFiles}>
              {value ? 'Replace' : 'Add'} Photo
            </Button>
          )}
        </Files>
        <Button variant="outlined" size="small" className={styles.button} startIcon={<ClearIcon />} onClick={handleClearPhoto}>
          Remove Photo
        </Button>
      </div>
    </div>
  );
})

export default PhotoUploadField;