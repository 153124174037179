import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        position: "absolute",
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
})

const LoadingPage = React.memo(() => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
            <CircularProgress />
        </div>
    )
})

export default LoadingPage