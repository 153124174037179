
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Fade, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        maxWidth: "1200px",
        margin: "auto",
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '50%',
        minWidth: '300px',
        // padding: '15px 0 0 0',
        padding: '1rem',
        // maxHeight: '98%'
        height: '90%',
        minHeight: '200px',
        maxHeight: '300px',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    infogroup: {
        margin: '6px auto 6px 1em',
        paddingTop: '10px'
    },
    headerText: {
        margin: 'auto 0 auto 0'
    },
    marker: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    sidemargin: {
        margin: '0 3px 0 3px'
    },
})

export const SegmentInfoPopup = React.memo(({ id, handleClose, respondentData }) => {
    const styles = useStyles();

    return (
        <Modal
            className={styles.modal}
            open={!!id}
            onClose={handleClose}
            // closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 250,
            }}
            disablePortal
        >
            <Fade in={!!id}>
                <Paper className={styles.paper}>
                <div className={styles.header}>
                        <Typography variant="h5" className={styles.headerText}>Guest Segment Answers</Typography>
                    </div>
                    <div className={styles.infogroup}>
                        {Object.entries(respondentData[id].segments).map(([question, answer], i) => (
                            <div className={styles.marker} key={`marker-display${i}`}>
                                <Typography variant="subtitle1">{question}</Typography>
                                <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                                {(!!answer && typeof answer === 'object' && !Array.isArray(answer)) ? Object.entries(answer).map(([answerKey, answerValue]) => (<Typography variant="subtitle2">{answerKey} - {answerValue}</Typography>))
                                    : <Typography variant="subtitle2">{Array.isArray(answer) ? answer.join(', ') : answer}</Typography>}
                            </div>
                        ))}
                    </div>
                </Paper>
            </Fade>
        </Modal>
    )

})