import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "@material-table/core"

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '1rem',
    boxSizing: 'border-box'
  },
  table: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  controls: {
    //border: 'solid 2px green',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1rem'
    // width: '100%'
  },
  button: {
    margin: '1em',
  },
});

const TransferListField = React.memo(({ value, sublabel, disabled, onChange, invalid, fullList, listKey }) => {
  const styles = useStyles()
  const [selectedOut, setSelectedOut] = useState([])
  const [selectedIn, setSelectedIn] = useState([])

  const checkedRef = useRef([])

  useEffect(() => {
    console.log('SETTING CHECKED REF TO', [...selectedOut, ...selectedIn])
    checkedRef.current = [...selectedOut, ...selectedIn]
  }, [selectedOut, selectedIn])

  const handleSelectedOut = useCallback((rows) => {
    setSelectedOut(rows.map(x => x.chimeUserId))
  }, []);

  const handleSelectedIn = useCallback((rows) => {
    setSelectedIn(rows.map(x => x.chimeUserId))
  }, []);

  const handleMoveIn = useCallback((event) => {
    const newValue = [...value, ...selectedOut]
    checkedRef.current = [...selectedIn]
    setSelectedOut([])
    onChange(null, newValue)
  }, [value, selectedOut, selectedIn, onChange]);

  const handleMoveOut = useCallback((event) => {
    const newValue = [...value].filter(x => !selectedIn.includes(x))
    checkedRef.current = [...selectedOut]
    setSelectedIn([])
    onChange(null, newValue)
  }, [value, selectedOut, selectedIn, onChange]);

  const groupedList = useMemo(() => {
    console.log('GROUPING LIST...', checkedRef.current)
    return fullList.reduce((p, c) => {
      if (c.tableData) {
        console.log('TABLE DATA', c.tableData)
        c.tableData.checked = checkedRef.current.includes(c[listKey])
      }
      p[value.includes(c[listKey]) ? 'in' : 'out'].push(c)
      return p
    }, { in: [], out: [] })
  }, [fullList, listKey, value])

  console.log('TRANSFER LIST VALUE', value)

  return (
    <>
      <div className={styles.root}>
        <div className={styles.table}>
          <Typography variant="h5">Available {sublabel}</Typography>
          <MaterialTable
            style={{ height: '100%' }}
            key='transfer-list-out'
            options={{
              emptyRowsWhenPaging: false,
              selection: true,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: 'stepped',
              pageSizeOptions: []
            }}
            columns={[
              { title: "First Name", field: "firstName", filtering: false },
              { title: "Last Name", field: "lastName", filtering: false },
              // { title: "Email", field: "email", filtering: false },
              { title: "Zoom Alias", field: "alias", filtering: false }
            ]}
            data={groupedList.out} //MATERIAL TABLE MUTATES PROPS :(
            title={` `}
            onSelectionChange={handleSelectedOut}
          />
        </div>
        <div className={styles.controls}>
          <Button
            variant="outlined"
            size="small"
            className={styles.button}
            onClick={handleMoveIn}
            disabled={!selectedOut.length}
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={styles.button}
            onClick={handleMoveOut}
            disabled={!selectedIn.length}
          >
            ≪
          </Button>
        </div>
        <div className={styles.table}>
          <Typography variant="h5">Current {sublabel}</Typography>
          <MaterialTable
            style={{ height: '100%' }}
            key='transfer-list-in'
            options={{
              emptyRowsWhenPaging: false,
              selection: true,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: 'stepped',
              pageSizeOptions: []
            }}
            columns={[
              { title: "First Name", field: "firstName", filtering: false },
              { title: "Last Name", field: "lastName", filtering: false },
              // { title: "Email", field: "email", filtering: false },
              { title: "Zoom Alias", field: "alias", filtering: false }
            ]}
            data={groupedList.in} //MATERIAL TABLE MUTATES PROPS :(
            title={` `}
            onSelectionChange={handleSelectedIn}
          />
        </div>
      </div>
    </>
  );
})

TransferListField.defaultProps = {
}

export default TransferListField;
