import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUser } from './recoil/atoms'
// import { Zoom, Grow, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import AppNavBar from './components/AppNavBar';
// \\import SettingsPage from './pages/SettingsPage';
import { ErrorPage } from './pages/ErrorPage';
import SessionDialPage from './SessionDialPage';
import DialSessionsPage from './pages/DialSessionsPage';
import LoadingPage from './pages/LoadingPage'
import config from './config.json'
import DialProjectsPage from './pages/DialProjectsPage';
// import './App.css'

const useStyles = makeStyles({
  app: {
    height: '100%',
    width: '100%',
    minHeight: '100vh',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'hidden'
  },
  transition: {
    position: 'relative',
    width: 'auto',
    height: '100vh',
    overflow: 'hidden'
  },
  body: {
    position: 'relative',
    width: 'auto',
    height: '100%',
    // padding: '1rem',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
})

const AppMain = React.memo((props) => {
  console.log('render app main', props)
  const styles = useStyles();
  const user = useRecoilValue(currentUser);

  console.log('user is: ', user)

  // useEffect(() => {
  //   if (!user) {
  //     props.history.push('/login')
  //   }
  // }, [user])

  useEffect(() => {
    if (!props.client && !user.accessLevels.includes('dial')) {
      window.location = config.smcUrl
    }
  }, [user, props.client])

  return (
    // <ThemeProvider theme={props.theme}>
    <div className={styles.app}>
      <AppNavBar />
      <div className={styles.body}>
        {/* <Zoom
          in={match != null}
          timeout={300}
          unmountOnExit> */}
        {/* <TransitionGroup>
          <CSSTransition
            key={props.location.pathname}
            timeout={{ enter: 300, exit: 0 }}
            // timeout={300}
            classNames="page"
            mountOnEnter
            unmountOnExit
          >
            <div className={styles.body}> */}
        <Switch location={props.location}>
          {/* <Route exact path={['/sessions/current', '/sessions/current/:action', '/']} render={() =>
            <SessionsPage filter="current" />
          } />
          <Route exact path='/sessions/upcoming' render={() =>
            <SessionsPage filter="upcoming" />
          } />
          <Route exact path='/sessions/past' render={() =>
            <SessionsPage filter="past" />
          } /> */}
          <Route exact path={['/session/:sessionId', '/client/:sessionId', '/project/:projectId']} render={(routeProps) => <SessionDialPage {...routeProps} sessionId={props.sessionId} projectId={props.projectId} client={props.client} />} />
          {/* <Route exact path='/session/:sessionId/guests' component={GuestsPage} /> */}
          {/* <Route exact path='/settings' render={() => <SettingsPage />} /> */}
          <Route exact path={['/','/sessions']} render={() => <DialSessionsPage />} />
          <Route exact path='/projects' render={() => <DialProjectsPage />} />
          <Route exact path='/authcallback' render={() => <LoadingPage />} />
          <Route render={() => <ErrorPage error="Not Found" message="Page Not Found" />} />
        </Switch>
        {/* </div>
          </CSSTransition>
        </TransitionGroup> */}
        {/* </Zoom> */}
        {/* <div className={styles.footer} /> */}
      </div>
    </div>
    // </ThemeProvider>
  );
})

export default AppMain;
