import React from 'react';
import { Chip, Divider, Typography, Badge } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VideocamIcon from '@material-ui/icons/Videocam';
import YouTubeIcon from '@material-ui/icons/YouTube';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
// import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
// import SecurityIcon from '@material-ui/icons/Security';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import TheatersIcon from '@material-ui/icons/Theaters';
import MovieIcon from '@material-ui/icons/Movie';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow'
// import StopIcon from '@material-ui/icons/Stop'
import { format } from 'date-fns'
import moment from 'moment'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '2em'
    },
    category: {
        // flexBasis: '20%'
        textAlign: 'right',
        marginRight: '6px'
    },
    info: {
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'space-evenly',
        width: '100%'
    },
    segment: {
        maxWidth: '400px',
        minWidth: '300px',
        width: '40%',
        // marginLeft: 'auto',
        margin: '12px 24px 12px 24px',
        padding: '6px',
        border: '2px solid #eee',
        borderRadius: '8px',
    },
    question: {
        display: 'flex',
        flexDirection: 'row',
    },
    answers: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    answer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    goal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    box: {
        margin: '6px'
    },
    group: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '3px',
        borderBottom: '2px solid black'
    },
    section: {
        marginBottom: '6px'
    },
    infogroup: {
        margin: '6px auto 6px 1em'
    },
    marker: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    sidemargin: {
        margin: '0 3px 0 3px'
    },
    label: {
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    titlegroup: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '8px'
    },
    chip: {
        margin: '1px',
        fontSize: '0.6125rem'
    },
    pfm: {
        margin: '1px',
        backgroundColor: '#6C54A4'
    },
    textIcon: {
        marginRight: '0.5em'
    },
    textRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    youtube: {
        margin: '1px',
        backgroundColor: '#e52d27'//'#b31217'
    }
})

// const LabelBadge = withStyles((theme) => ({
//     badge: {
//         transform: 'scale(1) translate(110%, -50%)'
//     },
// }))(Badge);

const timeStringFromMS = (time) => {
    const hours = time / (3600000)
    const remainder = time % (3600000)
    return `${Math.floor(hours)}`.padStart(2, '0') + ":" + `${Math.floor((remainder) / (60000))}`.padStart(2, '0') + ":" + `${Math.ceil((remainder) % (60000) / (1000))}`.padStart(2, '0')
}

// export const timeStringFromSeconds = (time) => {
//     const hours = time / (3600)
//     const remainder = time % (3600)
//     return `${Math.floor(hours)}`.padStart(2, '0') + ":" + `${Math.floor((remainder) / 60)}`.padStart(2, '0') + ":" + `${Math.ceil((remainder) % 60)}`.padStart(2, '0')
// }
const sessionTime = (time) => {
    const localTimezone = moment.tz.guess()
    return moment.tz(time, localTimezone).format('MMMM Do YYYY, h:mm a z')
}

const SessionTableDetails = React.memo(({ id, sessionName, scheduledStartTime, staffArrivalTime, guestCheckInTime, seaJobCode, team, client, isPfmScreening, pfmScreeningId, isYoutubeEvent, youtubeVideoUrl, isLiveSession, videoTitle, hasSurvey, surveyId, surveyType, videoMarkers, audienceSegments, dialChartSegments, isDialSession, scoreBase, dialStyle, sessionBranding, gaugeStyle, numberOfMeters, sliderKnob, sliderKnobColor, videoId, playerId, sessionStartTime, sessionEndTime, videoLength, started, liveVideo, liveStartTime, manualStartTime, include2fa, allowMobileDevices, allowedBrowsers, watermarkOpacity, preventLateEntry, lateEntryTimeLimit, limitDisconnect, disconnectCountLimit, disconnectTimeLimit, created, createdBy, lastUpdated, lastUpdatedBy, dialMode, symbolOptions, includeTuneOut, inactiveReminder }) => {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <div className={styles.top}>
                <div className={styles.titlegroup}>
                    <Typography variant="h4">{sessionName}</Typography>
                    <div className={styles.textRow}>
                        {isYoutubeEvent ? <YouTubeIcon className={styles.textIcon} /> : <VideocamIcon className={styles.textIcon} />}
                        <Typography variant="h6">{videoTitle}</Typography>
                    </div>
                    <div className={styles.textRow}>
                        <ScheduleIcon className={styles.textIcon} />
                        <Typography variant="h5">{sessionTime(scheduledStartTime)}</Typography>
                    </div>
                    {/* <div className={styles.textRow}>
                        <Typography variant="overline">STAFF ARRIVAL TIME:</Typography>
                        <Typography variant="overline">{new Date(staffArrivalTime).toLocaleDateString()}&nbsp;{new Date(staffArrivalTime).toLocaleTimeString()}</Typography>
                    </div>
                    <div className={styles.textRow}>
                        <Typography variant="overline">GUEST CHECK-IN TIME:</Typography>
                        <Typography variant="overline">{new Date(guestCheckInTime).toLocaleDateString()}&nbsp;{new Date(guestCheckInTime).toLocaleTimeString()}</Typography>
                    </div> */}
                    <div className={styles.textRow}>
                        <Typography variant="overline">STAFF - {sessionTime(staffArrivalTime)}</Typography>
                        <Typography variant="h5">&nbsp;|&nbsp;</Typography>
                        <Typography variant="overline">GUESTS - {sessionTime(guestCheckInTime)}</Typography>
                    </div>
                </div>
                <div className={styles.titlegroup}>
                    {isPfmScreening && <Chip color="primary" className={styles.pfm} label={`PFM SCREENING #${pfmScreeningId}`} size="small"></Chip>}
                    {isYoutubeEvent && <Chip color="primary" className={styles.youtube} label={`YOUTUBE${isLiveSession && ' LIVE'}`} size="small"></Chip>}
                    <Chip className={styles.chip} label={`ID ${id} - ${team}`} size="small" variant="outlined"></Chip>
                    {/* <Chip className={styles.chip} label={`JOB ${seaJobCode}`} size="small" variant="outlined" color="primary"></Chip> */}
                    {/* <Chip className={styles.chip} label={`TEAM ${team}`} size="small" variant="outlined" color="primary"></Chip> */}
                    <Chip className={styles.chip} label={`${client}`} size="small" variant="outlined" color="secondary"></Chip>
                </div>
            </div>

            {videoId && <div className={styles.info}>

                <div className={styles.box}>
                    <div className={styles.header}>
                        <OndemandVideoIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Video Info</Typography>
                    </div>
                    <div className={styles.infogroup}>
                        {/* {sessionStartTime && <Typography>Video Start Time: {format(new Date(liveStartTime || sessionStartTime), 'MMMM d yyyy, h:mm a')}</Typography>} */}
                        {videoLength && <Typography>Video Length: {timeStringFromMS(videoLength)}</Typography>}
                        {videoId && <Typography>Brightcove Video ID: {videoId}</Typography>}
                        {/* {playerId && <Typography>Brightcove Player ID: {playerId}</Typography>}
                        <Typography>Video Player Branding: {sessionBranding}</Typography> */}
                    </div>
                </div>
            </div>}

            {hasSurvey && <div className={styles.info}>
                <div className={styles.box}>
                    <div className={styles.header}>
                        <AssignmentIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Survey Settings</Typography>
                    </div>
                    <div className={styles.infogroup}>
                        <Typography>Survey Type: {surveyType}</Typography>
                        <Typography>Survey ID: {surveyId}</Typography>
                    </div>
                </div>
            </div>}

            <div className={styles.info}>
                {!!(videoMarkers && videoMarkers.length) && <div className={styles.box}>
                    <div className={styles.header}>
                        <MovieIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Video Markers</Typography>
                    </div>
                    <div className={styles.infogroup}>
                        {videoMarkers.map((marker, i) =>
                            <div className={styles.marker} key={`marker-display${i}`}>
                                <Typography variant="subtitle2">{`${marker.markerHour}`.padStart(2, '0')}:{`${marker.markerMinute}`.padStart(2, '0')}:{`${marker.markerSecond}`.padStart(2, '0')}</Typography>
                                <Typography className={styles.sidemargin} variant="subtitle1">&mdash;</Typography>
                                <Typography variant="subtitle1">{marker.markerName}</Typography>
                            </div>
                        )}
                    </div>
                </div>}
            </div>

            {/* <div className={styles.info}>
                <div className={styles.box}>
                    <div className={styles.header}>
                        <SecurityIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Security Settings</Typography>
                    </div>
                    <div className={styles.infogroup}>
                        <Typography>Require 2-Factor Authentication: {include2fa ? 'YES' : 'NO'}</Typography>
                        <Typography>Allow Mobile Devices: {allowMobileDevices ? 'YES' : 'NO'}</Typography>
                        <Typography>Allowed Browsers: {allowedBrowsers.join(', ')}</Typography>
                        <Typography>Watermark Opacity: {watermarkOpacity ? `${watermarkOpacity}%` : '15%'}</Typography>
                        <Typography>Prevent Late Entry: {preventLateEntry ? 'YES' : 'NO'}</Typography>
                        {preventLateEntry && <>
                            <Typography>Late Entry Time Limit: {lateEntryTimeLimit} minutes</Typography>
                        </>}
                        <Typography>Limit Disconnect: {limitDisconnect ? 'YES' : 'NO'}</Typography>
                        {limitDisconnect && <>
                            <Typography>Disconnect Time Limit: {disconnectTimeLimit} minutes</Typography>
                            <Typography>Disconnect Count Limit: {disconnectCountLimit}</Typography>
                        </>}
                    </div>
                </div>
            </div> */}


            {/* <div className={styles.info}>
                {!!(audienceSegments && audienceSegments.length) && <div className={styles.box}>
                    <div className={styles.header}>
                        <QuestionAnswerIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Available Segments</Typography>
                    </div>
                    <div className={styles.group}>
                        {audienceSegments.map((segment, i) =>
                            <Badge key={`segment-display${i}`} className={styles.segment} badgeContent="MULTI-SELECT" color="primary" invisible={!segment.multiple}>
                                <div className={styles.info}>
                                    <Typography variant="subtitle2" className={styles.label}>
                                        <span>{segment.questionKey}</span>
                                    </Typography>
                                    <Divider />
                                    <div className={styles.question}>
                                        <Typography className={styles.category} variant="subtitle1">Question:</Typography>
                                        <Typography variant="subtitle1">{segment.question}</Typography>
                                    </div>
                                    <div className={styles.question}>
                                        <Typography className={styles.category} variant="subtitle1">Answers:</Typography>
                                        <div className={styles.answers}>
                                            {segment.answers.map((answer, x) =>
                                                <div className={styles.answer}>
                                                    <Typography key={`segment-display${i}-answer${x}`} variant="subtitle1">{answer.answer}</Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Badge>
                        )}
                    </div>
                </div>}
            </div> */}

            <div className={styles.info}>
                {!!(dialChartSegments && dialChartSegments.length) && <div className={styles.box}>
                    <div className={styles.header}>
                        <ShowChartIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Chart Segments</Typography>
                    </div>
                    <div className={styles.group}>
                        {dialChartSegments.map((segment, i) =>
                            <Badge key={`chart-segment-display${i}`} className={styles.segment} badgeContent="MULTI-SELECT" color="primary" invisible={!segment.multiple}>
                                <div className={styles.info}>
                                    <Typography variant="subtitle2" className={styles.label}>
                                        <span>{segment.questionKey}</span>
                                    </Typography>
                                    <Divider />
                                    {/* <div className={styles.question}>
                                        <Typography className={styles.category} variant="subtitle1">Question:</Typography>
                                        <Typography variant="subtitle1">{segment.question}</Typography>
                                    </div> */}
                                    <div className={styles.question}>
                                        <Typography className={styles.category} variant="subtitle1">Segments:</Typography>
                                        <div className={styles.answers}>
                                            {segment.answers.map((answer, x) =>
                                                <div className={styles.answer}>
                                                    <Typography key={`chart-segment-display${i}-answer${x}`} variant="subtitle1">{answer.answer}</Typography>
                                                    <CheckCircleIcon fontSize="small" style={{ color: '#81c784' }} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Badge>
                        )}
                    </div>
                </div>}
            </div>

            <div className={styles.info}>


                {/* {!!(dialChartSegments && dialChartSegments.length) && <div className={styles.box}>
                    <Typography className={styles.header}>Audience Segments</Typography>
                    {dialChartSegments.map((segment, i) =>
                        <div key={`segment-display${i}`}>
                            <Typography>{segment.question}</Typography>
                            {segment.answers.map((answer, x) =>
                                <Typography key={`segment-display${i}-answer${x}`}>{answer}</Typography>
                            )}
                        </div>
                    )}
                </div>} */}

                {isDialSession && <div className={styles.box}>
                    <div className={styles.header}>
                        <PhonelinkSetupIcon fontSize="small" className={styles.textIcon} />
                        <Typography>Real-Time Feedback Settings</Typography>
                    </div>
                    <div className={styles.infogroup}>
                        <Typography>Activity Type: {dialMode === 'symbol' ? 'Emoji' : dialMode === 'score' ? 'Dial' : 'Tune Out Only'}</Typography>
                        {dialMode === 'score' ? <>
                            <Typography>Max Score: {scoreBase}</Typography>
                            <Typography>Dial Style: {dialStyle}</Typography>
                            {dialStyle !== 'Embedded Slider' && <>
                                <Typography>Gauge Style: {gaugeStyle}</Typography>
                            </>}
                            <Typography>Number of Meters: {numberOfMeters}</Typography>
                            {dialStyle === 'Embedded Slider' && <>
                                <Typography>Slider Knob: {sliderKnob}</Typography>
                                <Typography style={{ display: 'flex', alignItems: 'baseline' }}>Slider Knob Color: <div style={{ height: '10px', width: '20px', marginLeft: '3px', backgroundColor: sliderKnobColor }}></div></Typography>
                            </>}
                        </> : dialMode === 'symbol' ? <>
                            <Typography>Emoji Options: [<span style={{ fontFamily: 'Noto Color Emoji' }}>{symbolOptions.map(i => i.emoji).join(',')}</span>]</Typography>
                            <Typography>Tune Out: {includeTuneOut ? 'YES' : 'NO'}</Typography>
                            <Typography>Inactivity Reminder: {inactiveReminder ? 'YES' : 'NO'}</Typography>
                        </> : <>
                            <Typography></Typography>
                        </>}
                    </div>
                </div>}
            </div>

            <div className={styles.info}>
                <div className={styles.box}>
                    <Divider />
                    <div className={styles.titlegroup}>
                        <Typography variant="caption">Session Created at {format(new Date(created), 'MMMM d yyyy, h:mm a')} by {createdBy}</Typography>
                        <Typography variant="caption">Last Updated at {format(new Date(lastUpdated), 'MMMM d yyyy, h:mm a')} by {lastUpdatedBy}</Typography>
                        <Typography variant="caption">SE/ASI Job Code {seaJobCode}</Typography>
                    </div>
                </div>
            </div>

        </div>
    );
})

export default withTheme(SessionTableDetails)