import React, { useEffect, useState, useRef, useCallback } from 'react';
import { IconButton, Typography, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    // width: '100%'
  },
  marker: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  textField: {
    width: '100%'
  },
  timeField: {
    width: '3.5em'
  },
  colon: {
    padding: '3px'
  },
  datePicker: {
    flexBasis: '35%',
    // minWidth: '35%',
    //width: '100%'
  }
});

let intg = /[0-9]+/

const TimeMarkerField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, onRemove }) => {
  const [, setUpdate] = useState({})
  const inputRef = useRef({})
  const styles = useStyles()
  const valueRef = useRef();

  valueRef.current = value

  // console.log('time marker render', valueRef.current)

  useEffect(() => {
    if (Number.isInteger(valueRef.current.markerHour) && Number.isInteger(valueRef.current.markerMinute) && Number.isInteger(valueRef.current.markerHour)) {
      inputRef.current = { markerHour: 2, markerMinute: 2, markerSecond: 2}
      setUpdate({})
    }
    // console.log('time marker', valueRef.current, inputRef.current)
  },[])

  const handleLabelChange = useCallback((event) => {
    const marker = { ...valueRef.current }
    marker.markerName = event.target.value
    onChange(event, marker)
  }, [onChange]);

  const handleTimeChange = useCallback((event, key) => {
    if (intg.test(event.target.value) || event.target.value === '') {
      inputRef.current[key] = event.target.value.length
      const marker = { ...valueRef.current }
      const newValue = event.target.value !== '' ? parseInt(event.target.value) : null
      if (key !== 'markerHour' && newValue >= 60) {
        marker[key === 'markerMinute' ? 'markerHour' : 'markerMinute']++
        marker[key] = newValue - 60
      } else {
        marker[key] = newValue
      }
      onChange(null, marker)
    }
  }, [onChange]);

  const handleBlur = useCallback((event, key) => {
    if (event.target.value.length) {
      inputRef.current[key] = 2
      setUpdate({})
    }
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.marker}>
        <TextField className={styles.timeField} error={!(typeof value.markerHour === 'number')} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" placeholder="HH" disabled={disabled} value={(value.markerHour !== null && value.markerHour !== undefined) ? inputRef.current.markerHour === 2 ? `${value.markerHour}`.padStart(2, '0') : `${value.markerHour}` : ''} onChange={(e) => handleTimeChange(e, 'markerHour')} onBlur={(e) => handleBlur(e, 'markerHour')} />
        <Typography className={styles.colon}>:</Typography>
        <TextField className={styles.timeField} error={!(typeof value.markerMinute === 'number')} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" placeholder="MM" disabled={disabled} value={(value.markerMinute !== null && value.markerMinute !== undefined) ? inputRef.current.markerMinute === 2 ? `${value.markerMinute}`.padStart(2, '0') : `${value.markerMinute}` : ''} onChange={(e) => handleTimeChange(e, 'markerMinute')} onBlur={(e) => handleBlur(e, 'markerMinute')} />
        <Typography className={styles.colon}>:</Typography>
        <TextField className={styles.timeField} error={!(typeof value.markerSecond === 'number')} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" placeholder="SS" disabled={disabled} value={(value.markerSecond !== null && value.markerSecond !== undefined) ? inputRef.current.markerSecond === 2 ? `${value.markerSecond}`.padStart(2, '0') : `${value.markerSecond}` : ''} onChange={(e) => handleTimeChange(e, 'markerSecond')} onBlur={(e) => handleBlur(e, 'markerSecond')} />
      </div>
      <TextField className={styles.textField} variant="outlined" size="small" placeholder={placeholder || 'Enter marker label'} error={!value.markerName} disabled={disabled} value={value.markerName || ''} onChange={handleLabelChange} />
      {onRemove && <IconButton disabled={disabled} onClick={onRemove}>
        <DeleteIcon />
      </IconButton>}
    </div>
  );
}, (prev, next) => prev.value.markerName === next.value.markerName && prev.value.markerHour === next.value.markerHour && prev.value.markerMinute === next.value.markerMinute && prev.value.markerSecond === next.value.markerSecond)

TimeMarkerField.defaultProps = {
  label: 'Marker:'
}

export default TimeMarkerField;
