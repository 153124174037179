import React from 'react'
import { Drawer } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import ChimeChat from './ChimeChat';

const useStyles = makeStyles({
    drawer: {
        width: 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))',
        flexShrink: 0,
    },
    drawerPaper: {
        width: 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))',
    },
    // toastBackgroundColor: {
    //     backgroundColor: '#000000'
    // },
    // toastTextColor: {
    //     color: '#000000'
    // },
    // toastIconColor: {
    //     color: '#000000 !important'
    // }
});

const ChatDrawer = React.memo(({ open = false, ...props }) => {
    const styles = useStyles()

    return (
        <>
            <Drawer
                className={styles.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: styles.drawerPaper,
                }}
            >
                <ChimeChat visible={open} {...props} />
            </Drawer>
        </>
    );

})

export default withTheme(ChatDrawer)