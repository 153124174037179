import React, { useCallback } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    // width: '100%'
  },
  marker: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  timeField: {
    width: '3.5em'
  },
  text: {
    padding: '3px'
  },
});

let intg = /[0-9]+/

const MinuteInputField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, onRemove }) => {
  const styles = useStyles()

  console.log('minute render', value)
  console.log('placeholder', placeholder)

  const handleValueChange = useCallback((event) => {
    const newValue = event.target.value
    if (intg.test(newValue) || newValue === '') {
      onChange(null, newValue === '' ? null : parseInt(newValue))
    }
  }, [onChange]);

  return (
    <div className={styles.root}>
      <Typography className={styles.label}>{label}</Typography>
      <div className={styles.marker}>
        <TextField className={styles.timeField} placeholder={placeholder || ''} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" error={invalid} disabled={disabled} value={value} onChange={handleValueChange} />
        <Typography className={styles.text}>minutes</Typography>
      </div>
    </div>
  );
})

MinuteInputField.defaultProps = {
  label: 'Minutes:'
}

export default MinuteInputField;
