import React, { useCallback } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  datePicker: {
    flexBasis: '35%',
    // minWidth: '35%',
    //width: '100%'
  }
});

const DateField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalidate, allowPast }) => {
  const styles = useStyles()

  const handleChange = useCallback(newValue => {
    onChange(null, newValue)
  }, [onChange]);

  return (
    <div className={styles.root}>
      <Typography className={styles.label}>{label}</Typography>
      <DatePicker
        renderInput={props => <TextField {...props} disabled={disabled} helperText={null} variant="outlined" size="small" inputProps={{ ...props.inputProps, placeholder: placeholder || 'Enter Date/Time' }} />}
        // label=''
        // disableToolbar
        disablePast={!allowPast}
        // format="MM/DD/YY HH:mm:ss A"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
})

DateField.defaultProps = {
  label: 'Date'
}

export default DateField;
