import React, { useCallback } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    // width: '100%'
  },
  marker: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  textField: {
    width: '100%'
  },
  timeField: {
    width: '3.5em'
  },
  colon: {
    padding: '3px'
  },
  datePicker: {
    flexBasis: '35%',
    // minWidth: '35%',
    //width: '100%'
  }
});

let intg = /[0-9]+/

const PercentInputField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, onRemove }) => {
  const styles = useStyles()
  // const valueRef = useRef();

  // valueRef.current = value

  console.log('percent render', value)

  const handleValueChange = useCallback((event) => {
    const newValue = event.target.value
    if (intg.test(newValue) || newValue === '') {
      onChange(null, newValue)
    }
  }, [onChange]);

  // const handleBlur = useCallback((event, key) => {
  //   if (event.target.value.length) {
  //     const newValue = event.target.value
  //     setInputValue(prev => {
  //       return { ...prev, [key]: newValue.padStart(2, '0') }
  //     })
  //   }
  // }, [])

  return (
    <div className={styles.root}>
      <Typography className={styles.label}>{label}</Typography>
      <div className={styles.marker}>
        <TextField className={styles.timeField} placeholder={placeholder || ''} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" error={invalid} disabled={disabled} value={value} onChange={handleValueChange} />
        <Typography className={styles.colon}>%</Typography>
      </div>
    </div>
  );
})

PercentInputField.defaultProps = {
  label: 'Percentage:'
}

export default PercentInputField;
