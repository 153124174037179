import React, { useCallback } from 'react';
import { Typography, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        //border: 'solid 2px green',
        display: 'flex',
        padding: '5px',
        alignItems: 'center',
        width: '100%'
    },
    checkbox: {
        flexBasis: '20%',
        // minWidth: '15%',
        textAlign: 'right',
        marginRight: '10px'
    },
    label: {
        flexBasis: '80%'
    }
});

const CheckboxField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalidate }) => {
    const styles = useStyles()

    const handleChange = useCallback((event) => {
        event.currentTarget.blur()
        onChange(null, event.target.checked)
    }, [onChange]);

    return (
        <div className={styles.root}>
            <div className={styles.checkbox}>
                <Checkbox size="small" color="primary" checked={value} disabled={disabled} onChange={handleChange} />
            </div>
            <Typography className={styles.label}>{label}</Typography>
        </div>
    );
})

CheckboxField.defaultProps = {
    label: 'Text'
}

export default CheckboxField;
