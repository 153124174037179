import React, { useRef, useCallback } from 'react';
import { Route, Switch, withRouter, BrowserRouter as Router } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SnackbarProvider } from 'notistack';
import App from './App';
import OktaLogin from './pages/OktaLogin';
// import LoginCallback from './components/auth/LoginCallback'
// import SecureRoute from './components/auth/SecureRoute'
import MagicLogin from './pages/MagicLogin'
import ClientPortal from './pages/ClientPortal'
import ClientApp from './ClientApp';
import config from './config.json'

const oktaAuthObj = new OktaAuth({
    issuer: config.oktaConfig.issuer,
    clientId: config.oktaConfig.clientId,
    originalUri: window.location.origin,
    redirectUri: window.location.origin + '/authcallback',
    responseType: ['id_token', 'token'],
    scopes: ['openid', 'email', 'profile', 'groups', 'appdata', 'offline_access'],
    pkce: false
});

oktaAuthObj.tokenManager.on("added", (key, newToken) => {
    console.log(`OKTA token added`)
})

oktaAuthObj.tokenManager.on("error", (error) => {
    console.log('OKTA ERROR', JSON.stringify(error))
})
oktaAuthObj.tokenManager.on("expired", (key, token) => {
    console.log("OKTA token has expired")
})

oktaAuthObj.tokenManager.on('renewed', function (key, newToken, oldToken) {
    console.log("OKTA renewed----", newToken, ('accessToken' in newToken));
});

const baseUrl = config.oktaConfig.issuer.replace(/\/oauth2\/.+/, '')

const AppRoutes = React.memo(({ history, location: { pathname } }) => {
    const redirected = useRef()

    const onAuthRequired = useCallback(() => {
        console.log("LOGIN REQUIRED", pathname, pathname.includes('ml/', 0))
        if (pathname !== '/login' && pathname !== '/authcallback' && !pathname.includes('client/', 0) && !redirected.current) {
            // authService.setFromUri(pathname)
            history.push('/login')
            redirected.current = true
        }
    }, [history, pathname])

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    // const onSessionExpired = useCallback(() => {
    //     console.log("EXPIRED")
    //     if (pathname !== '/login' && pathname !== '/authcallback' && !pathname.includes('viewer/', 0) && !redirected.current) {
    //         // authService.setFromUri(pathname)
    //         history.push('/login')
    //         redirected.current = true
    //     }
    // }, [history, pathname])

    return (
        <Security
            oktaAuth={oktaAuthObj}
            onAuthRequired={onAuthRequired}
            // onSessionExpired={onSessionExpired}
            restoreOriginalUri={restoreOriginalUri}
        >
            <SnackbarProvider maxSnack={10} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }} autoHideDuration={6000}>
                <Route exact path='/login' render={() => <OktaLogin baseUrl={baseUrl} />} />
                <Route exact path='/authcallback' component={LoginCallback} />
                <Switch>
                    <Route exact path='/client/:sessionId' component={ClientApp} />
                    <Route exact path="/client/project/:projectId" component={ClientPortal} />
                    <Route exact path='/client/login/:linkId' component={MagicLogin} />
                    <SecureRoute path="**" component={App} />
                </Switch>
                {/* <Route exact path='/viewer/:sessionId' component={ClientApp} />
            <SecureRoute component={App} /> */}
            </SnackbarProvider>
        </Security>
    )
})

export default withRouter(AppRoutes);