import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { CompactPicker } from 'react-color'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  textField: {
    flexBasis: '80%'
    // width: '100%'
  }
});

const ColorInputField = React.memo(({ value, label, disabled, placeholder, invalid, onChange, ...colorProps }) => {
  const styles = useStyles()

  const handleChange = useCallback((color) => {
    onChange(null, (color && color.hex) || null)
  }, [onChange]);

  return (
    <div className={styles.root}>
      <Typography className={styles.label}>{label}</Typography>
      <div style={{ filter: disabled ? 'grayscale(0.75)' : 'none', border: `1px solid ${invalid ? '#f44336' : 'transparent'}`, pointerEvents: disabled ? 'none' : 'auto' }}>
        <CompactPicker className={styles.colorField} color={value || {}} onChangeComplete={handleChange} {...colorProps} />
      </div>
    </div>
  );
})

ColorInputField.defaultProps = {
  label: 'color'
}

export default ColorInputField;
