import React, { useCallback } from 'react';
import { Typography, TextField, Fade } from '@material-ui/core';
import { MobileDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone'

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  label: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  tz: {
    // flexBasis: '20%',
    textAlign: 'left',
    marginLeft: '10px'
  },
  datePicker: {
    // flexBasis: '35%',
    minWidth: '12em',
    //width: '100%'
  },
  selectField: {
    flexBasis: '20%',
    margin: '0 18px 0 18px'
  }
});

const zone = moment.tz.guess()
const timezone = moment.tz(zone).format("z")

const DateTimeField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, allowPast, showTimezone }) => {
  const styles = useStyles()

  const handleChange = useCallback(newValue => {
    onChange(null, newValue)
  }, [onChange]);

  return (
    <div className={styles.root}>
      <Typography className={styles.label}>{label}</Typography>
      <MobileDateTimePicker
        renderInput={props => <TextField {...props} helperText={null} variant="outlined" size="small" error={invalid} inputProps={{ ...props.inputProps, placeholder: placeholder || 'Enter Date/Time' }} />}
        // autoOk
        disablePast={!allowPast}
        // format="MM/DD/YY HH:mm:ss A"
        // labels={true}
        // showToolbar={true}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        TransitionComponent={<Fade disableStrictModeCompat />}
      />
      {!!showTimezone && <Typography className={styles.tz}>{timezone}</Typography>}
      {/* <Select
        // value={chatStatus}
        // onChange={handleChatStatus}
        displayEmpty
        className={styles.selectField}
      >
        <MenuItem value='ALL'>ALL</MenuItem>
        <MenuItem value='Not Ready'>Not Ready</MenuItem>
        <MenuItem value='Waiting'>Waiting</MenuItem>
        <MenuItem value='Watching'>Watching</MenuItem>
      </Select> */}
    </div>
  );
})

DateTimeField.defaultProps = {
  label: 'Date/Time'
}

export default DateTimeField;
