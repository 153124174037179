
import React, { useState, useMemo, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { Modal, Fade, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Tab, Tabs, IconButton } from '@material-ui/core';
import { SegmentInfoPopup } from './SegmentInfoPopup';

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        maxWidth: "1200px",
        margin: "auto",
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '80%',
        minWidth: '300px',
        // padding: '15px 0 0 0',
        padding: '1rem',
        // maxHeight: '98%'
        minHeight: '200px',
        maxHeight: '250px',
    },
    table: {
        // margin: '1rem 0 1rem 0'
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            height: '6px',
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5',
            borderRadius: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '6px',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#888'
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #F5F5F5'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    headerText: {
        margin: 'auto 0 auto 0'
    },
    tableCell: {
        fontSize: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 2rem 0 2rem'
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0.5rem'
    },
    button: {
        margin: '0 0.25rem 0 0.25rem'
    }
})

const formatTime = (time, padAll) => {
    const s = `${Math.floor(time) % 60}`.padStart(2, '0')
    const m = Math.floor(time / 60) % 60
    const h = Math.floor(time / 60 / 60)
    return h || padAll ? `${padAll ? h.toString().padStart(2, '0') : h}:${m.toString().padStart(2, '0')}:${s}` : (m ? `${m.toString().padStart(2, '0')}:${s}` : ` :${s}`)
}

export const ButtonReasonsPopup = React.memo(({ open, handleClose, buttons, customButtons, respondentData }) => {
    const styles = useStyles();

    const tableRef = useRef()

    const [segment, setSgment] = useState('all')
    const [selected, setSelected] = useState([...customButtons.keys()][0])
    const [openSegmentInfo, setOpenSegmentInfo] = useState();

    // console.log(buttons, segment, customButtons)
    // console.log(buttons[segment][selected])

    const handleCloseSegmentInfo = useCallback(() => {
        setOpenSegmentInfo()
    }, [])

    const tabs = useMemo(() => {
        return [...customButtons.entries()].map(([tab, btn]) => (
            <Tab label={btn.label} value={tab} />
        ))
    }, [customButtons])

    const changeSelected = useCallback((event, newSelected) => {
        setSelected(newSelected)
    }, [])

    return (
        <>
            <Modal
                className={styles.modal}
                open={open}
                onClose={handleClose}
                // closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 250,
                }}
                disablePortal
            >
                <Fade in={open}>
                    <Paper className={styles.paper}>
                        <div className={styles.header}>
                            <Typography variant="h5" className={styles.headerText}>Audience Feedback</Typography>
                        </div>
                        <Tabs
                            value={selected}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={changeSelected}
                        >
                            {tabs}
                        </Tabs><br />
                        <TableContainer className={styles.table} component={Paper}>
                            <Table size="small" ref={tableRef}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Video Time</TableCell>
                                        <TableCell>Guest ID</TableCell>
                                        <TableCell>Feedback</TableCell>
                                        <TableCell>Segments</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {buttons && buttons[segment] && buttons[segment][selected]?.map((button) => (<>
                                        <TableRow>
                                            <TableCell>{formatTime(button.videoTime, true)}</TableCell>
                                            {button.reasons.map((x) => <>
                                                <TableCell><p style={{ margin: 0 }}>{x.gid}</p></TableCell>
                                                <TableCell><p style={{ margin: 0 }}>{x.reason}</p></TableCell>
                                                <TableCell><IconButton style={{padding: 0}} color="inherit" onClick={() => setOpenSegmentInfo(x.gid)}>
                                                    <InfoIcon fontSize="small" />
                                                </IconButton></TableCell>
                                            </>)}
                                        </TableRow>
                                    </>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Fade>
            </Modal>
            {openSegmentInfo && <SegmentInfoPopup id={openSegmentInfo} handleClose={handleCloseSegmentInfo} respondentData={respondentData} />}
        </>
    )

})