import React, { useCallback } from 'react';
import { Checkbox, Drawer, FormControl, FormControlLabel, FormLabel, Slider, Typography, Select, MenuItem, Switch, Button } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { CompactPicker } from 'react-color'

// const brightColors = [
//   '#f44e3b', '#f4aa3b', '#e2f43b', '#85f43b', '#3bf44e', '#3bf4aa', '#3be2f4', '#3b85f4', '#4d3bf4','#aa3bf4', '#f43be2', '#f43b85',
//   '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#333333', '#808080', '#cccccc',
//   '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E', '#000000', '#666666', '#B3B3B3',
// ]

const vividColors = [
    '#a70004', '#a75000', '#a7a400', '#57a700', '#04a700', '#00a750', '#00a7a4', '#0057a7', '#0003a7', '#5000a7', '#a400a7', '#a70057',
    '#ff0a10', '#ff7f0a', '#fffa0a', '#8aff0a', '#10ff0a', '#0aff80', '#0afffa', '#0a8aff', '#0a0fff', '#800aff', '#fa0aff', '#ff0a8a',
    '#ff6c70', '#ffb26c', '#fffc6c', '#b9ff6c', '#70ff6c', '#6cffb3', '#6cfffc', '#6cb9ff', '#6c6fff', '#b36cff', '#fc6cff', '#ff6cb9',
]

// const useStyles = makeStyles({

// })

const ChartSettingsDrawer = React.memo(({ drawerOpen, toggleDrawer, dataKeys, showAll, toggleShowAll, lineWidth, changeLineWidth, lineColor, changeLineColor, markerColor, markerWidth, chartPrecision, changeChartPrecision, scorePrecision, changeScorePrecision, videoOpacity, showMarkerLabels, showSegmentLabels, toggleShowMarkerLabels, toggleShowSegmentLabels, chartSize, changeChartSize, client, toggleShowMinutes, onVideoOpacityChange, segmentFont, onSegmentFontChange, onMarkerWidthChange, onMarkerColorChange, graphType, changeGraphType, dialMode, symbolKeys, isChartViewFull, toggleChartView, aggregationInterval, singleView, toggleView, segmentsMap, changeCurrentSegmentGroup, currentSegmentGroup, currentSegment, changeCurrentSegment, changeCurrentSymbol, currentSymbol, resetAllOptions }) => {
    // const [colorPickerAnchor, setColorPickerAnchor] = useState()
    // const handlePickerOpen = useCallback((event) => {
    //     setColorPickerAnchor(event.currentTarget)
    // }, []);

    // const handlePickerClose = useCallback(() => {
    //     setColorPickerAnchor(null)
    // }, []);
    // const styles = useStyles();
    return (
        <Drawer
            anchor='right'
            open={drawerOpen}
            onClose={toggleDrawer}
        >
            <div style={{ padding: '0 1.5rem 0 1.5rem', textAlign: 'center' }}>
                <Typography variant="overline" style={{ fontSize: '1rem' }}>Chart Options</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '0.5rem 0 0.5rem 0' }}>
                    <FormControl component="fieldset" style={{ width: '100%', margin: '5px 0 5px 0' }}>
                        <FormLabel component="legend" style={{ marginBottom: '6px' }}>Chart Precision</FormLabel>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <ToggleButtonGroup value={chartPrecision} exclusive onChange={changeChartPrecision}>
                                <ToggleButton value={0}>REALTIME</ToggleButton>
                                <ToggleButton value={1}>{dialMode === 'score' ? 1 : aggregationInterval}s</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </FormControl>
                    {dialMode === 'score' ? <>
                        <FormControl component="fieldset" style={{ width: '100%', margin: '5px 0 5px 0' }}>
                            <FormLabel component="legend" style={{ margin: '6px 0px 6px 0' }}>Score Precision</FormLabel>
                            {/* <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <ToggleButtonGroup value={scorePrecision} exclusive onChange={changeScorePrecision}>
                                <ToggleButton style={{ paddingTop: 0, paddingBottom: 0, fontSize: '160%', fontFamily: "'Segoe UI Local', 'Segoe UI Web (West European)'" }} value={0}>{'1\u20441'}</ToggleButton>
                                <ToggleButton style={{ paddingTop: 0, paddingBottom: 0, fontSize: '160%', fontFamily: "'Segoe UI Local', 'Segoe UI Web (West European)'" }} value={1}>{'1\u204410'}</ToggleButton>
                                <ToggleButton style={{ paddingTop: 0, paddingBottom: 0, fontSize: '160%', fontFamily: "'Segoe UI Local', 'Segoe UI Web (West European)'" }} value={2}>{'1\u2044100'}</ToggleButton>
                                <ToggleButton style={{ paddingTop: 0, paddingBottom: 0, fontSize: '160%', fontFamily: "'Segoe UI Local', 'Segoe UI Web (West European)'" }} value={3}>{'1\u20441000'}</ToggleButton>
                            </ToggleButtonGroup>
                        </div> */}
                            <FormControlLabel
                                style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                                control={<Select
                                    value={scorePrecision}
                                    onChange={changeScorePrecision}
                                // displayEmpty
                                // className={styles.selectField}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    {/* <MenuItem value={3}>3</MenuItem> */}
                                </Select>}
                                label=" decimal places"
                            />
                            {/* <FormControlLabel
                            control={<Checkbox checked={showMinutes} onChange={toggleShowMinutes} name="showMinutes" />}
                            label="Show Time in Minutes"
                        /> */}
                        </FormControl>
                    </> : dialMode === 'symbol' ?
                        <>
                            <Typography variant="overline" style={{ fontSize: '1rem' }}>View Mode</Typography>
                            {/* <FormControl component="fieldset" style={{ width: '100%', margin: '5px 0 5px 0' }}>
                                <FormLabel component="legend" style={{ margin: '6px 0px 6px 0' }}>Chart Type {graphType}</FormLabel>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                                    control={<Select
                                        value={graphType}
                                        onChange={changeGraphType}
                                    >
                                        <MenuItem value='line'>Line</MenuItem>
                                        <MenuItem value='bar'>Stacked Bar</MenuItem>
                                        {singleView && <MenuItem value='bar100'>100% Stacked Bar</MenuItem>}
                                        <MenuItem value='area'>Area</MenuItem>
                                        {singleView && <MenuItem value='area100'>100% Stacked Area</MenuItem>}
                                    </Select>}
                                />
                            </FormControl> */}
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                <FormLabel component="legend" style={{ marginBottom: '6px' }}>Show By:</FormLabel>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <div >
                                        Segment
                                        <Switch size="small" checked={singleView} onChange={toggleView} />
                                        Emoji
                                    </div>
                                </div>
                            </FormControl>
                            {singleView ? <FormControl component="fieldset" style={{ width: '100%', margin: '5px 0 5px 0' }}>
                                <FormLabel component="legend" style={{ margin: '6px 0px 6px 0' }}>Show Segment:</FormLabel>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                                    control={<Select
                                        value={currentSegment}
                                        onChange={changeCurrentSegment}
                                    >
                                        {dataKeys.filter(key => (showAll || key !== 'all')).map(key => <MenuItem key={key} value={key}>{key === 'all' ? 'Total' : key}</MenuItem>)}
                                    </Select>}
                                />
                            </FormControl> : <>
                                <FormControl component="fieldset" style={{ width: '100%', margin: '5px 0 5px 0' }}>
                                    <FormLabel component="legend" style={{ margin: '6px 0px 6px 0' }}>Show Emoji</FormLabel>
                                    <FormControlLabel
                                        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                                        control={<Select
                                            value={currentSymbol}
                                            onChange={changeCurrentSymbol}
                                        >
                                            {symbolKeys.map(({ emoji, tooltip }) => <MenuItem key={emoji} value={emoji}><span style={{ fontFamily: 'Noto Color Emoji' }}>{emoji}</span> - {tooltip}</MenuItem>)}
                                        </Select>}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" style={{ width: '100%', margin: '5px 0 5px 0' }}>
                                    <FormLabel component="legend" style={{ margin: '6px 0px 6px 0' }}>Show Segment Group</FormLabel>
                                    <FormControlLabel
                                        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                                        control={<Select
                                            value={currentSegmentGroup}
                                            onChange={changeCurrentSegmentGroup}
                                        >
                                            {Object.keys(segmentsMap).map((segment) => <MenuItem key={segment} value={segment}>{segment === 'all' ? 'Total' : segment}</MenuItem>)}
                                        </Select>}
                                    />
                                </FormControl>
                            </>}
                        </> : <></>}
                    {dialMode !== 'symbol' && <FormControl component="fieldset" style={{ width: '100%', margin: '5px 0 5px 0' }}>
                        <FormControlLabel
                            control={<Checkbox checked={showAll} onChange={toggleShowAll} name="showAll" />}
                            label="Show Cumulative Score"
                        />
                    </FormControl>}
                </div>
                {dialMode !== 'buttonOnly' ? <>
                    <div style={{ margin: '0.5rem 0 0.5rem 0' }}>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" style={{ marginBottom: '6px' }}>Video Opacity</FormLabel>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <Slider value={videoOpacity} min={0} max={1} step={0.01} onChange={onVideoOpacityChange} />
                                <Typography variant="subtitle1" style={{ width: '4rem', paddingLeft: '0.5rem' }}>{Math.round(videoOpacity * 100)}%</Typography>
                            </div>
                        </FormControl>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" style={{ marginBottom: '6px' }}>Chart View</FormLabel>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <div >
                                    Bottom View
                                    <Switch size="small" checked={isChartViewFull} onChange={toggleChartView} />
                                    Full View
                                </div>
                            </div>
                        </FormControl>
                    </div>
                    <Typography variant="overline" style={{ fontSize: '1rem' }}>{dialMode === 'score' ? 'Line Width/Color' : 'Styling'}</Typography>
                    {dataKeys.filter(key => (showAll || key !== 'all'))/*.concat('respondents')*/.map((datakey) => (
                        <div style={{ margin: '1rem 0 1rem 0' }} key={`linewidth${datakey}`}>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                <FormLabel component="legend" style={{ marginBottom: '6px' }}>{datakey[0].toUpperCase() + datakey.slice(1)}
                                </FormLabel>
                                <div style={{ margin: '0.5rem 0 1rem 0' }}>
                                    <CompactPicker colors={vividColors} color={lineColor[datakey] || lineColor['default']} onChangeComplete={(c) => changeLineColor(datakey, c)} />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <ToggleButtonGroup value={lineWidth[datakey] || lineWidth['default']} exclusive onChange={(e, v) => changeLineWidth(datakey, v)}>
                                        <ToggleButton value={1.0}>1.0</ToggleButton>
                                        <ToggleButton value={1.8}>1.8</ToggleButton>
                                        <ToggleButton value={2.6}>2.6</ToggleButton>
                                        <ToggleButton value={3.4}>3.4</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </FormControl>
                        </div>
                    ))}
                </> : <></>}
                {dialMode === 'symbol' && symbolKeys.map((datakey) => (
                    <div style={{ margin: '0.5rem 0 0.5rem 0' }} key={`linewidth${datakey.tooltip}`}>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" style={{ marginBottom: '6px', color: 'rgba(0,0,0,1)', fontSize: '1.3rem', fontFamily: 'Noto Color Emoji' }}>{datakey.emoji}</FormLabel>
                            <div style={{ margin: '0.5rem 0 1rem 0' }}>
                                <CompactPicker colors={vividColors} color={lineColor[datakey.emoji] || lineColor['default']} onChangeComplete={(c) => changeLineColor(datakey.emoji, c)} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <ToggleButtonGroup value={lineWidth[datakey.emoji] || lineWidth['default']} exclusive onChange={(e, v) => changeLineWidth(datakey.emoji, v)}>
                                    <ToggleButton value={1.0}>1.0</ToggleButton>
                                    <ToggleButton value={1.8}>1.8</ToggleButton>
                                    <ToggleButton value={2.6}>2.6</ToggleButton>
                                    <ToggleButton value={3.4}>3.4</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </FormControl>

                    </div>
                ))}
                <div style={{ margin: '0.5rem 0 0.5rem 0' }} key={`videomarkerwidth`}>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" style={{ marginBottom: '6px' }}>Video Markers</FormLabel>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <ToggleButtonGroup value={markerWidth} exclusive onChange={onMarkerWidthChange}>
                                <ToggleButton value={1.0}>1.0</ToggleButton>
                                <ToggleButton value={1.8}>1.8</ToggleButton>
                                <ToggleButton value={2.6}>2.6</ToggleButton>
                                <ToggleButton value={3.4}>3.4</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </FormControl>
                    <div style={{ margin: '1rem 0 1rem 0' }}>
                        <CompactPicker colors={vividColors} color={markerColor} onChangeComplete={onMarkerColorChange} />
                    </div>
                </div>
                <Typography variant="overline" style={{ fontSize: '1rem' }}>{dialMode === 'score' ? 'Dial Stats' : 'Stats'}</Typography>
                <div style={{ margin: '0.5rem 0 0.5rem 0' }}>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" style={{ marginBottom: '6px' }}>Font Size</FormLabel>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {/* <Slider aria-label="Restricted values" value={segmentFont} min={50} max={70} step={1} onChange={onSegmentFontChange} />
                            <Typography variant="subtitle1" style={{ width: '4rem', paddingLeft: '0.5rem' }}>{Math.round(segmentFont)}%</Typography> */}
                            <ToggleButtonGroup value={segmentFont} exclusive onChange={onSegmentFontChange}>
                                <ToggleButton value={0}>+0.0</ToggleButton>
                                <ToggleButton value={50}>+1.0</ToggleButton>
                                <ToggleButton value={56}>+2.0</ToggleButton>
                                <ToggleButton value={65}>+3.0</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </FormControl>
                </div>
                {(!client) && <>
                    <Typography variant="overline" style={{ fontSize: '1rem' }}>PNG Export Options</Typography>
                    <div style={{ margin: '0.5rem 0 0.5rem 0' }}>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" style={{ marginBottom: '6px' }}>PNG Size</FormLabel>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <ToggleButtonGroup value={chartSize} exclusive onChange={changeChartSize}>
                                    <ToggleButton value={'l'}>LARGE</ToggleButton>
                                    <ToggleButton value={'m'}>MEDIUM</ToggleButton>
                                    <ToggleButton value={'s'}>SMALL</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <FormControlLabel
                                control={<Checkbox checked={showMarkerLabels} onChange={toggleShowMarkerLabels} name="showMarkerLabels" />}
                                label="Show Video Marker Labels"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showSegmentLabels} onChange={toggleShowSegmentLabels} name="showSegmentLabels" />}
                                label="Show Segment Labels"
                            />
                            {/* <FormControlLabel
                            control={<Checkbox checked={showAll} onChange={toggleShowAll} name="showAll" />}
                            label="Show All (Cumulative Score)"
                        /> */}
                        </FormControl>
                    </div>
                </>}
                {dialMode !== 'buttonOnly' && <div style={{ display: 'flex', flexDirection: 'column', margin: '0.5rem 0 0.5rem 0' }}>
                    <FormControl component="fieldset" style={{ width: '100%', margin: '5px 0 5px 0' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button variant='outlined' onClick={resetAllOptions}>
                                Reset
                            </Button>
                        </div>
                    </FormControl>
                </div>}
            </div>
        </Drawer >
    );

})

export default withTheme(ChartSettingsDrawer);
