import config from '../config.json'
import worker from 'workerize-loader?inline!../utils/fetchWorker' // eslint-disable-line import/no-webpack-loader-syntax

const fetchWorker = worker()

export const getSessionsPromise = ({ includeDeleted, offset }, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/sessions?offset=${offset || 0}&includeDeleted=${!!includeDeleted}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data //data.records || data.Items
    })

// export const getAllSessions = (includeDeleted, token, sessions = []) => getSessionsPromise({includeDeleted}, sessions.length, token).then((resp) => {
//     sessions.push(...resp.records)
//     const more = sessions.length !== resp.count;
//     if (more) {
//         return getAllSessions(includeDeleted, token, sessions)
//     } else {
//         return sessions
//     }
// })

export const getAllSessions = ({ includeDeleted = false, offset = 0 }, token) =>
    fetchWorker.threadedFetch(`https://${config.rest.sessionManagementAPI}/sessions`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        params: { includeDeleted, offset }
    }, true).then(data => {
        return data
    })

export const getProjectDataPromise = (projectId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/client/project?projectId=${projectId}`, {
        method: 'GET',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        console.log(data)
        return data
    })

export const getSessionDataPromise = (sessionId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/client/session?sessionId=${sessionId}`, {
        method: 'GET',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const updateSessionPromise = (session, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/session`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(session)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const getZoomMeetingDetailsPromise = (meetingId, registrantId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/zoom/details?meetingId=${meetingId}&registrantId=${registrantId}`, {
        method: 'GET',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })