import React, { useCallback } from 'react';
import { Typography, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    //border: 'solid 2px green',
    display: 'flex',
    // padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  subroot: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  sublabel: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: ({label}) => label ? 'calc(20% + 16px)' : '10px'
  },
  textField: {
    flexBasis: ({width}) => `${width}`,
    margin: '5px'
    // width: '100%'
  }
});

const TextInputField = React.memo(({ value, label, disabled, placeholder, maxLength, onChange, invalid, large, endComponent, sublabel, fullWidth }) => {
  const styles = useStyles({label, width: fullWidth ? '100%' : '80%'})

  const handleChange = useCallback((event) => {
    onChange(null, event.target.value)
  }, [onChange]);

  return (
    <>
      <div className={styles.root}>
        {label && <Typography className={styles.label}>{label}</Typography>}
        <TextField className={styles.textField} inputProps={{ maxLength: maxLength || 524288 }} variant="outlined" size="small" error={invalid} disabled={disabled} value={value || ''} placeholder={placeholder || 'Enter text'} onChange={handleChange} multiline={large} rows={large ? 3 : 1 } InputProps={{
          endAdornment: (
            endComponent && <InputAdornment position="end">
              {typeof endComponent === 'function' ? endComponent() : endComponent}
            </InputAdornment>
          ),
        }}/>
      </div>
      {sublabel && <div className={styles.subroot}>
        <Typography variant="caption" className={styles.sublabel}>{typeof sublabel === 'function' ? sublabel() : sublabel}</Typography>
      </div>}
    </>
  );
})

// TextInputField.defaultProps = {
//   label: 'Text'
// }

export default TextInputField;
